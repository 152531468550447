<template>
  <v-dialog v-model="value" persistent scrollable max-width="50vw">
    <v-overlay :value="updating">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
        <h4 class="mt-4">Updating products, please wait....</h4>
      </div>
    </v-overlay>
    <v-card >
      <v-toolbar dark color="primary">
        <v-toolbar-title >
          <h3>{{variant.longDesc || 'New Product Variant'}}</h3>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title class="mb-4">
        Variant Details
      </v-card-title>
      <v-card-text>
      <v-form ref="formbase" v-model="formValid" lazy-validation @submit.prevent>
        <v-form-base class="mt-2" v-if="variant" :row="{ noGutters:false }"  :col="{cols:12, sm:6, md:6 }" :model="variant" :schema="schema" @input="log" />
      </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!isDirty || !formValid" color="success" class="mr-4" @click.native="saveVariant()">
          Save Variant
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductTypeApi from '@/api/productTypes'
import ProductTypeVariantApi from '@/api/productTypeVariants'
import vFormBase from '@/components/vFormBase'

export default {
  props: {
    value: Boolean,
    data: Object,
    edit: Boolean,
    variants: Array
  },
  components: {
    vFormBase
  },
  async mounted() {
  },
  data() {
    return {
      productTypes: [],
      updating: false,
      isDirty: false,
      formValid: true,
      rules: {
        required: (value) => !!value || 'Required.',
        uniqueVariantCode: (value) => {
          const check = _.filter(this.variants, {'productTypeCode': this.variant.productTypeCode, 'productTypeVariantCode': value?.toUpperCase() })
          return check.length === 0 || `Variant ${value?.toUpperCase()} is already in use for this product`
        }
      }
    }
  },
  computed: {
    schema() {
      return {
        productTypeCode: {
          type: 'select',
          label: 'Product',
          items: this.productTypes,
          itemText: 'title',
          outlined: true, dense: true,
          itemValue: 'productTypeCode',
          disabled: this.variant._id ? true : false
        },
        productTypeVariantCode: {
          type: 'text',
          label: 'Variant',
          rules: this.variant._id ? [] : [this.rules.uniqueVariantCode],
          outlined: true, dense: true,
          disabled: this.variant._id ? true : false
        },
        height: { type: 'number', label: 'Height', outlined: true, dense: true , disabled: this.variant._id ? true : false},
        width: { type: 'number', label: 'Width', outlined: true, dense: true, disabled: this.variant._id ? true : false },
        units: {
          type: 'select',
          label: 'Units',
          items: ['mm', 'in'],
          outlined: true,
          dense: true
        },
        unitPrice: { type: 'number', label: 'Unit Price', outlined: true, dense: true  },
        customPrice: { type: 'number', label: 'Custom Price', outlined: true, dense: true  },
        // artistCommission: { type: 'number', step: 0.01, label: 'Artist Commission', outlined: true, dense: true  },
        title: { type: 'text', label: 'Title', outlined: true, dense: true , rules: [this.rules.required] },
        longDesc: { type: 'text', label: 'Description', outlined: true, dense: true  },
      }
    },
    variant() {
      return this.data
    }
  },
  async created() {
    ProductTypeApi.loadProductTypes().then((results) => {
      this.productTypes = results.data
    }).catch(
      err => {
        console.log('err', err)
      }
    )
  },
  methods: {
    close() {
      this.$emit('close')
    },
    saveVariant() {
      this.updating = true
      ProductTypeVariantApi.saveProductTypeVariant(this.variant).then((results) => {
        this.updating = false
        this.$store.dispatch('products/getProductTypeVariants')
      }).catch(
        err => {
          console.log('err', err)
          this.updating = false
        }
      )
        this.isDirty = false
    },
    log(v){
      this.variant.productTypeVariantCode && this.variant.productTypeVariantCode.toUpperCase()
      this.isDirty = true
      this.formValid = this.$refs.formbase.validate()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.v-dialog__content {
  align-items: start;
  margin-top: 50px
}
</style>
